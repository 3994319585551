import React from "react";
import TopNav from "../../components/TopNav";
import BottomNav from "../../components/BottomNav";
import Footer from "../../components/footer/Footer";

const AboutUs = () => {
  return (
    <>
      <TopNav />
      <BottomNav />
      <section className="about_us">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <h1>About Us</h1>
              <p style={{ lineHeight: "2em" }}>
                Makkaan.com – Pakistan’s Top Real Estate Investment Platform
                Makkaan.com is a trustworthy and responsible platform that aims
                to provide you with all the Residential, Commercial, and
                Investment details under the same roof. The only podium that
                offers reliable services to investors, dealers, societies, and
                end-users of the real estate world throughout the country. We
                use an all-inclusive marketing strategy that covers all aspects
                of projects and helps investors at every stage of the process
                with complete transparency. As a company, Makkaan.com values
                teamwork and interdisciplinary as a means to attain expertise
                and excellence in our work. Our company’s dedicated
                professionals uphold our fundamental values, which seek to
                provide our clients with research-based, cost-effective, more
                straightforward, and speedier real estate solutions.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: "#F3F7F9" }} className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="text-center">
                <img
                  src={require("../../assets/images/makaan infographic.png")}
                  width="80%"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <p style={{ lineHeight: "2em" }}>
                Through our exciting series of Podcasts (Makkaan k Mehmaan) and
                Makkaan on Mic, presented by our Marketing Head Mr. Shehnoor
                Ahmed, we consider and value our youth about Real Estate
                Development by admiring real estate stars and inspirational
                personalities. In addition, we will provide a pathway for youth
                to enhance their real estate skills through multiple seminars,
                job fairs, and awareness programs in different universities and
                offer internships to future realtors. Being one of the Top Real
                Estate Investment platforms, we cope with end-user problems
                through our new initiative of Real Estate Content Bank, where we
                are onboarding 980 Real Estate Companies at a single Platform.
                To know more about these series, you can go through our social
                media platforms. The core values make Makkaan.com the most
                trusting stage for our clients in Pakistan and abroad. Quality,
                Transparency, Sustainability, Integrity. Building trust and
                honesty in our relationships with clients is one of our primary
                goals. We turn savings into capital and capital into a
                full-blown enterprise for honest stockholders and customers
                worldwide because we measure our success by the success of our
                clients.
              </p>
            </div>
          </div>

          <div className="row g-4 mt-4">
            <div className="col-md-6 position-relative">
              <div
                className=" d-flex rounded-3 shadow align-items-center bg-white"
                style={{ height: "273px" }}
              >
                <div className="py-5 ps-3">
                  <div>
                    <h5>Our Mission</h5>

                    <small>
                      Through our exciting series of Podcasts (Makkaan k
                      Mehmaan) and Makkaan on Mic, presented by our Marketing
                      Head Mr. Shehnoor Ahmed, we consider and value our youth
                      about Real Estate Development by admiring real estate
                      stars and inspirational personalities. In addition, we
                      will provide a pathway for youth to enhance their real
                      estate skills through multiple seminars, job fairs, and
                      awareness programs in different universities and offer
                      internships to future realtors
                    </small>
                  </div>
                </div>
                <div className="p-2" style={{ width: "610px" }}>
                  <img
                    src={require("../../assets/images/about_1.png")}
                    width="100%"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 position-relative">
              <div
                className=" d-flex rounded-3 shadow align-items-center bg-white"
                style={{ height: "273px" }}
              >
                <div className="py-5 ps-3">
                  <div>
                    <h5>Our Vision</h5>
                    <small>
                      Our vision is to become the transparent country’s top
                      provider of real estate services. Building trust and
                      honesty in our relationships with clients is one of our
                      primary goals. Our primary focus is providing you with the
                      most transparent, secure, and best services possible while
                      making the process simple and uncomplicated.
                    </small>
                  </div>
                </div>
                <div className="p-2" style={{ width: "610px" }}>
                  <img
                    src={require("../../assets/images/about-4.png")}
                    width="100%"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutUs;
