import React, { useState, useEffect, useRef } from "react";
import TopNav from "../components/TopNav";
import BottomNav from "../components/BottomNav";
import Footer from "../components/footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdCall, MdEmail } from "react-icons/md";
import Akbarvista1 from "../makkaanpages/images/ayubia1.webp";
import Noc from "../makkaanpages/images/ayubia2.webp";
import Amenities from "../makkaanpages/images/ayubia3.webp";
import Const1 from "../makkaanpages/images/ayubia4.webp";
import Const2 from "../makkaanpages/images/ayubia5.webp";
import Ayubia from "../makkaanpages/images/9ksuitespp.jpeg";
import developerlogo from "../makkaanpages/images/9K-Suits-ayubia.png";
import makkaanLogo from "../makkaanpages/images/makkaanlogo.png";
import paymentplan from "../makkaanpages/pdf/9kayubiapp.pdf";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import "./CustomArrow.css";
import "../App.css";
import api_url from "../ApiUrl";
import axios from "axios";

import { SampleNextArrow, SamplePrevArrow } from "./CustomArrow";

function Ninek() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const stages = [
    { id: "media2", label: "July 2023", media: Const1 },
    { id: "media3", label: "Jan 2024", media: Const2 },
  ];

  const [activeStage, setActiveStage] = useState(stages[0].id);

  const handleNext = () => {
    const currentIndex = stages.findIndex((stage) => stage.id === activeStage);
    const nextIndex = (currentIndex + 1) % stages.length;
    setActiveStage(stages[nextIndex].id);
  };

  const handlePrev = () => {
    const currentIndex = stages.findIndex((stage) => stage.id === activeStage);
    const prevIndex = (currentIndex - 1 + stages.length) % stages.length;
    setActiveStage(stages[prevIndex].id);
  };

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settingss = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  // Tab bar Functionally
  const navRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const [navOffset, setNavOffset] = useState(0);

  useEffect(() => {
    if (navRef.current) {
      setNavOffset(navRef.current.offsetTop);
    }

    const handleScroll = () => {
      if (window.pageYOffset >= navOffset) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navOffset]);

  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const submit = () => {
    console.log("Submit button clicked");
    const fullName = document.getElementById("fullName");
    const phone = document.getElementById("phone");
    const city = document.getElementById("city");
    const email = "kirmani481@gmail.com";

    const yourMessage = document.getElementById("yourMessage");

    if (!fullName.value.trim()) {
      toast.error("Must enter name");
    } else if (!phone.value.trim()) {
      toast.error("Must enter phone");
    } else if (!yourMessage.value.trim()) {
      toast.error("Must enter message");
    } else {
      const params = {
        fullName: fullName.value.trim(),
        phone: phone.value.trim(),
        city: city.value.trim(),
        email: email,
        yourMessage: yourMessage.value.trim(),
      };
      console.log(params, "params data");

      axios
        .post(`${api_url}contact/create`, params)
        .then((res) => {
          if (res.data.status === "ok") {
            toast.success("Your message submitted successfully!");

            // Reload the page after 3 seconds
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            toast.error(res.data.message || "Error submitting your message");
          }
        })
        .catch((error) => {
          console.error("Submission error:", error);
          toast.error(
            error.response?.data?.message || "Error submitting your message"
          );
        });
    }
  };
  return (
    <>
      <div className="position-relative view_property">
        <ToastContainer />
        <div
          className=" position-fixed w-100"
          style={{ zIndex: "999999", marginTop: "-8em" }}
        >
          <TopNav />
          <BottomNav />
        </div>
        <div style={{ marginTop: "8em", zIndex: "0" }}>
          <div className="container mt-3 mb-3">
            <div className="row">
              <div className="col-sm-8">
                <Slider {...settings}>
                  <div>
                    <img
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      src={Akbarvista1}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div>
                    <img
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      src={Noc}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div>
                    <img
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      src={Amenities}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div>
                    <img
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      src={Const1}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div>
                    <img
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      src={Const2}
                      style={{
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Slider>
              </div>
              <div className="col-sm-4">
                <img
                  className="imagedata"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  src={Noc}
                  style={{ width: "100%", height: "auto", cursor: "pointer" }}
                />
                <img
                  className="imagedata"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  src={Amenities}
                  style={{
                    width: "100%",
                    height: "auto",
                    paddingTop: "0.89em",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex">
          <a href="#home-tab">
            <i
              className="bi bi-camera mx-2 icon-dark-bg"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              onClick={() => document.getElementById("home-tab").click()}
              style={{ cursor: "pointer" }}
            ></i>
          </a>
          <a href="#profile-tab">
            <i
              className="bi bi-camera-reels mx-2"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              onClick={() => document.getElementById("profile-tab").click()}
              style={{ cursor: "pointer" }}
            ></i>
          </a>
          <a>
            <i
              className="bi bi-geo-alt mx-2"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              onClick={() => document.getElementById("contact-tab").click()}
              style={{ cursor: "pointer" }}
            ></i>
          </a>
        </div>

        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          style={{ zIndex: "100000000000" }}
        >
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  9k Suites
                </h5>

                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Images
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Videos
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Map
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="slider-container p-5">
                      <Slider {...settingss}>
                        <div>
                          <img
                            src={Noc}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                        <div>
                          <img
                            src={Amenities}
                            style={{
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="p-5">
                      <ReactPlayer
                        url="https://www.youtube.com/watch?v=vkqLyPf0TUQ"
                        controls={true}
                        width="100%"
                        height="450px"
                      />
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="p-5">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d26447.51487854301!2d73.36312652029603!3d34.04542659210233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s9k%20Suites%20Nathiagali!5e0!3m2!1sen!2s!4v1721306438138!5m2!1sen!2s"
                        width="1000"
                        height="450"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Div  */}

        <div className="fixed-bar">
          <button type="button" class="btn btn-primary">
            Contact Us
          </button>

          <a href="+923311111049">
            <button className="col-sm-4 btn fixed-button">
              <i class="bi bi-telephone"></i> Call
            </button>
          </a>
          <a href="http://wa.me/+923311111045">
            <button className="col-sm-4 btn fixed-button">
              <i class="bi bi-whatsapp"></i>
            </button>
          </a>
        </div>
        {/* Tabs bars  */}
        <div
          ref={navRef}
          className={`mid-nav ${isFixed ? "fixed" : ""}`}
          id="midNav"
          style={{ zIndex: "10000000" }}
        >
          <div className="nav-buttons-container">
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("overview")}
            >
              Overview
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("amenities")}
            >
              Amenities
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("development")}
            >
              Development Updates
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("location")}
            >
              Location
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("payment")}
            >
              Payment Plans Floor Plans
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("marketed")}
            >
              Marketed By
            </button>
            <button
              className="nav-button"
              onClick={() => handleScrollToSection("developed")}
            >
              Developed By
            </button>
          </div>
        </div>
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Overview
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />

                    <p
                      style={{
                        paddingTop: "1em",
                      }}
                    >
                      9K Suites Nathia Gali is a GDA-approved project
                      strategically located in Nathia Gali. This project is
                      designed to provide both tranquility and adventure and
                      offers service apartments in affordable installments.
                    </p>

                    <ol class="list-group list-group">
                      <div class="fw-bold mb-2" style={{ color: "#4db749" }}>
                        Apartments
                      </div>
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                        <div
                          class="col-sm-5 ms-2 me-auto"
                          style={{ textAlign: "left" }}
                        >
                          <div class="fw-bold">Size (Min-Max) </div>
                          375 - 1250 (Sq.Ft)
                        </div>

                        <div
                          class="col-sm-5 ms-2 me-auto"
                          style={{ textAlign: "left" }}
                        >
                          <div class="fw-bold">Total Price (Min–Max)</div>
                          6,900,000 - 38,750,000 (PKR)
                        </div>
                        <a>
                          <i class="bi bi-buildings"></i>
                        </a>
                      </li>
                    </ol>
                    <ol class="list-group list-group">
                      <li class="list-group-item d-flex justify-content-between align-items-start mt-4">
                        <div class="col-sm-5 ms-2 me-auto">
                          <i class="bi bi-geo-alt"></i>
                          <div class="fw-bold">
                            Nathia Gali
                            <br /> Bahria Enclave in Islamabad.
                          </div>
                          Location
                        </div>
                        <div class="col-sm-5 ms-2 me-auto">
                          <i class="bi bi-person"></i>
                          <div class="fw-bold">9K SUITES </div>
                          Developer's
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                style={{
                  backgroundColor: "#3f4040",
                  padding: "2em",
                  borderRadius: "10px",
                }}
              >
                <h5 className="text-center text-white">MAKE AN ENQUIRY</h5>
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control rounded-1"
                          id="fullName"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <input
                          type="city"
                          className="form-control rounded-1"
                          id="city"
                          placeholder="city"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control rounded-1"
                          id="phone"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <textarea
                          className="form-control rounded-1"
                          placeholder="Message"
                          id="yourMessage"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        className="btn bg_makkan text-white w-100 mt-3"
                        style={{ backgroundColor: "#4DB749" }}
                        onClick={submit}
                      >
                        Submit
                      </button>
                    </div>

                    <div className="col-md-12 pt-4">
                      <div
                        className="d-flex justify-content-center "
                        style={{ gap: "12px" }}
                      >
                        <a href="#">
                          <button className="btn btn-danger  py-1 px-5">
                            <MdCall color="#fff" /> Call
                          </button>
                        </a>
                        <a href="#">
                          <button className="btn btn-primary  py-1 px-5">
                            <MdEmail color="#fff" /> Email
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5" id="amenities">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Amenties
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />
                    <div className="row">
                      <ol className="list-group list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../makkaanpages/images/terrace.png")}
                              alt=""
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#2ecc71",
                              }}
                            />
                            <div className="fw-bold">
                              Private terraces<br></br> with every unit
                            </div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("./images/lobby.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">
                              A welcoming <br />
                              lobby
                            </div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../makkaanpages/images/restaurant.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">Gourmet restaurant</div>
                          </div>
                        </li>
                      </ol>
                      <ol className="list-group list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../makkaanpages/images/lobby.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">
                              Exclusive executive lounge
                            </div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../makkaanpages/images/park.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />

                            <div className="fw-bold">
                              Play area for children
                            </div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <i class="bi bi-controller"></i>
                            <div className="fw-bold">Game room</div>
                          </div>
                        </li>
                      </ol>
                      <ol className="list-group list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../makkaanpages/images/dumbbell.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">Fully-equipped gym</div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <i class="bi bi-film"></i>
                            <div className="fw-bold">Mini cinema</div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <i class="bi bi-shop"></i>
                            <div className="fw-bold">Business center</div>
                          </div>
                        </li>
                      </ol>
                      <ol className="list-group list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../makkaanpages/images/croom.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">Multi-purpose hall</div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../makkaanpages/images/pray.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">Prayer area</div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <img
                              src={require("../makkaanpages/images/croom.png")}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div className="fw-bold">Conference room</div>
                          </div>
                        </li>
                      </ol>
                      <ol className="list-group list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-start mt-4 list-item-custom">
                          <div className="col-sm-4 ms-2 me-auto">
                            <i class="bi bi-shield-check"></i>
                            <div className="fw-bold">24/7 Security</div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <i class="bi bi-plug"></i>
                            <div className="fw-bold">Reliable power backup</div>
                          </div>
                          <div className="col-sm-4 ms-2 me-auto">
                            <i class="bi bi-lightning"></i>
                            <div className="fw-bold">
                              Advanced lightning protection
                            </div>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5" id="development">
          <div className="row">
            <div className="col-12">
              <div className="read-more-container">
                <h3 style={{ color: "#4db749", fontWeight: "bold" }}>
                  Development Updates
                </h3>
                <img src={require("../img/arrow.png")} alt="" />
                <div className="row">
                  <div className="col-12">
                    <div className="timeline">
                      {stages.map((stage, index) => (
                        <div key={stage.id} className="timeline-stage">
                          <div
                            className={`circle ${
                              activeStage === stage.id ? "active" : ""
                            }`}
                            onClick={() => setActiveStage(stage.id)}
                          >
                            {stage.label}
                          </div>
                          {index < stages.length - 1 && (
                            <div className="line"></div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <div className="media-container">
                      {stages.map((stage) => (
                        <div
                          key={stage.id}
                          className="media-content-wrapper"
                          style={{
                            display:
                              activeStage === stage.id ? "block" : "none",
                          }}
                        >
                          <div className="media-label">{stage.label}</div>
                          <img
                            id={stage.id}
                            src={stage.media}
                            alt={stage.label}
                            className=" media-content"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5" id="location">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Location
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />
                    <div className="row mt-3">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d26447.51487854301!2d73.36312652029603!3d34.04542659210233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s9k%20Suites%20Nathiagali!5e0!3m2!1sen!2s!4v1721306438138!5m2!1sen!2s"
                        width="1000"
                        height="450"
                        style={{ borderRadius: "20px" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5" id="payment">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-12">
                  <div className="read-more-container">
                    <h3
                      style={{
                        color: "#4db749",
                        fontWeight: "bold",
                      }}
                    >
                      Payment And Floor plans
                    </h3>
                    <img src={require("../img/arrow.png")} alt="" />
                    <div className="row mt-4">
                      <a
                        href={paymentplan}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div class="card" style={{ width: "18rem" }}>
                          <img
                            src={Ayubia}
                            class="card-img-top"
                            alt="..."
                            style={{ padding: "10px", borderRadius: "5px" }}
                          />
                          <div class="card-body">
                            <p class="card-text">9k Suites Ayubia</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-3 mt-3" id="marketed">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="card h-100">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={makkaanLogo}
                      className="img-fluid rounded-start"
                      alt="..."
                      style={{ padding: "15px", marginTop: "25px" }}
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Marketed By</h3>
                      <h5 className="card-title">Makkaan.com</h5>
                      <p>
                        Makkaan.com is a trustworthy and responsible platform
                        that aims to provide you with all the Residential,
                        Commercial, and Investment details under the same roof.
                      </p>
                      {isExpanded && (
                        <p>
                          The only podium that offers reliable services to
                          investors, dealers, societies, and end-users of the
                          real estate world throughout the country. We use an
                          all-inclusive marketing strategy that covers all
                          aspects of projects and helps investors at every stage
                          of the process with complete transparency.
                        </p>
                      )}
                      <a
                        onClick={toggleReadMore}
                        style={{ color: "green", cursor: "pointer" }}
                      >
                        {isExpanded ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="card h-100">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={developerlogo}
                      className="img-fluid rounded-start"
                      alt="..."
                      style={{ padding: "15px", marginTop: "25px" }}
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Developed By</h3>
                      <h5 className="card-title">9k Suites</h5>
                      <p>
                        The developer of 9k Suites Ayubia is Muqtadir Qazi. With
                        several years of experience in Real Estate, he is a
                        prominent name for 9k suites in Ayubia. Besides, he has
                        a highly skilled and professional team with careful
                        design. Planned, and developed various projects in the
                        state.
                      </p>
                      {isExpanded && (
                        <p>
                          The developers of 9k suites in Ayubia aim to provide
                          all the basic and modern features and amenities in
                          this project. So, this is one of the specific projects
                          that gives you to earn from your residence.
                        </p>
                      )}
                      <a
                        onClick={toggleReadMore}
                        style={{ color: "green", cursor: "pointer" }}
                      >
                        {isExpanded ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Ninek;
