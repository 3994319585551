import React, { useEffect, useState } from "react";
import TopNav from "../../components/TopNav";
import BottomNav from "../../components/BottomNav";
import Footer from "../../components/footer/Footer";

import HTMLReactParser from "html-react-parser";

import api_url from "../../ApiUrl";
import axios from "axios";
import { useParams } from "react-router-dom";

const ViewBlogs = () => {
  const { id, name } = useParams();

  const [myPost, setMyPost] = useState({});

  useEffect(() => {
    axios.get(api_url + `blog/get/${id}`).then((res) => {
      console.log(res.data, "mery blog===================================");
      setMyPost(res.data?.data);
    });
  }, []);

  return (
    <>
      <TopNav />
      <BottomNav />

      <div
        id="intro-example"
        class="p-5 text-center bg-image"
        style={{
          backgroundImage: `url('${require("./../../img/banner/Group 22 (6).png")}')`,
          height: "170px",
        }}
      ></div>

      <div class="container mt-5">
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12">
                <h1
                  style={{
                    fontWeight: "700",
                    fontSize: "24px",
                    lineHeight: "33.6px",
                  }}
                >
                  {myPost?.name}
                </h1>
                <ul
                  style={{ listStyle: "none", paddingLeft: "0px" }}
                  class="nav"
                >
                  <li>
                    <img
                      src={require("./../../img/house-2.png")}
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "10.51px",
                        marginLeft: "10px",
                      }}
                    >
                      {" "}
                      Makkan
                    </span>
                  </li>
                  <li style={{ marginLeft: "10px" }}>
                    <img
                      src={require("./../../img/calender.png")}
                      alt=""
                      style={{ width: "20px", height: "20px" }}
                    />
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "10.51px",
                        marginLeft: "10px",
                      }}
                    >
                      {myPost?.createdAt}
                    </span>
                  </li>
                </ul>
                <img
                  style={{
                    width: "100%",
                    height: "400px",
                    marginTop: "2em",
                    marginBottom: "2em",
                  }}
                  src={myPost?.image}
                  class="img-fluid w-100"
                  alt="Wild Landscape"
                />

                {typeof myPost?.description === "string" && (
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                    }}
                  >
                    {HTMLReactParser(myPost?.description)}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card p-3">
              <h3>
                <span
                  class="text-white bg_makkans"
                  style={{
                    fontWeight: "600",
                    fontSize: "21px",
                    lineHeight: "29.4px",
                  }}
                >
                  Latest{" "}
                </span>
                Post
              </h3>
              <div class="row">
                <div class="col-md-12" style={{ padding: "5px" }}>
                  <div class="card mb-3">
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img
                          style={{ width: "100%", height: "130px" }}
                          src={require("./../../img/projects/Famous-Deserts.webp")}
                          alt="Trendy Pants and Shoes"
                          class="img-fluid rounded-start"
                        />
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5
                            class="card-title"
                            style={{
                              fontWeight: "500",
                              fontSize: "17px",
                              lineHeight: "25.5px",
                            }}
                          >
                            Famous Deserts of Pakistan
                          </h5>
                          <ul
                            style={{ listStyle: "none", paddingLeft: "0px" }}
                            class="nav"
                          >
                            <li>
                              <img
                                src={require("./../../img/house-2.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <span
                                style={{
                                  fontWeight: "400",
                                  fontSize: "10.51px",
                                  lineHeight: "10.51px",
                                  marginLeft: "5px",
                                }}
                              >
                                {" "}
                                Makkan
                              </span>{" "}
                              |
                            </li>
                            <li style={{ marginLeft: "10px" }}>
                              <img
                                src={require("./../../img/calender.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <span
                                style={{
                                  fontWeight: "400",
                                  fontSize: "10.51px",
                                  lineHeight: "10.51px",
                                }}
                              >
                                {" "}
                                02 Decomber
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div class="col-md-12" style={{ padding: "5px" }}>
                  <div class="card mb-3">
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img
                          style={{ width: "100%", height: "130px" }}
                          src={require("./../../img/projects/17-Dams-Blog.webp")}
                          alt="Trendy Pants and Shoes"
                          class="img-fluid rounded-start"
                        />
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5
                            class="card-title"
                            style={{
                              fontWeight: "500",
                              fontSize: "17px",
                              lineHeight: "25.5px",
                            }}
                          >
                            17 Dams List in Pakistan That You Should Know About
                          </h5>
                          <ul
                            style={{ listStyle: "none", paddingLeft: "0px" }}
                            class="nav"
                          >
                            <li>
                              <img
                                src={require("./../../img/house-2.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <span
                                style={{
                                  fontWeight: "400",
                                  fontSize: "10.51px",
                                  lineHeight: "10.51px",
                                  marginLeft: "5px",
                                }}
                              >
                                {" "}
                                Makkan
                              </span>{" "}
                              |
                            </li>
                            <li style={{ marginLeft: "10px" }}>
                              <img
                                src={require("./../../img/calender.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <span
                                style={{
                                  fontWeight: "400",
                                  fontSize: "10.51px",
                                  lineHeight: "10.51px",
                                }}
                              >
                                {" "}
                                02 Decomber
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div class="col-md-12" style={{ padding: "5px" }}>
                  <div class="card mb-3">
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img
                          style={{ width: "100%", height: "130px" }}
                          src={require("./../../img/projects/5-Provinces-of-Pakistan-2-jpg.webp")}
                          alt="Trendy Pants and Shoes"
                          class="img-fluid rounded-start"
                        />
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5
                            class="card-title"
                            style={{
                              fontWeight: "500",
                              fontSize: "17px",
                              lineHeight: "25.5px",
                            }}
                          >
                            5 Provinces of Pakistan
                          </h5>
                          <ul
                            style={{ listStyle: "none", paddingLeft: "0px" }}
                            class="nav"
                          >
                            <li>
                              <img
                                src={require("./../../img/house-2.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <span
                                style={{
                                  fontWeight: "400",
                                  fontSize: "10.51px",
                                  lineHeight: "10.51px",
                                  marginLeft: "5px",
                                }}
                              >
                                {" "}
                                Makkan
                              </span>{" "}
                              |
                            </li>
                            <li style={{ marginLeft: "10px" }}>
                              <img
                                src={require("./../../img/calender.png")}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <span
                                style={{
                                  fontWeight: "400",
                                  fontSize: "10.51px",
                                  lineHeight: "10.51px",
                                }}
                              >
                                {" "}
                                02 Decomber
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="icon-container"
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <i
                  class="fab fa-facebook-f fa-2x"
                  style={{ color: "#3b5998" }}
                ></i>
                <i
                  class="fab fa-twitter fa-2x"
                  style={{ color: "#55acee" }}
                ></i>
                <i
                  class="fab fa-instagram fa-2x"
                  style={{ color: "#ac2bac" }}
                ></i>
                <i
                  class="fab fa-linkedin-in fa-2x"
                  style={{ color: "#0082ca" }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ViewBlogs;
