import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import api_url from "../ApiUrl";

function Agencystaff({ agencyId }) {
  const [agencyLogoss, setAgencyLogo] = useState([]);
  const [myImage, setMyImage] = useState([]);
  const [staffList, setStaffList] = useState([]);

  const submit = async () => {
    const fullName = document.getElementById("fullName");
    const phone = document.getElementById("phone");
    const city = document.getElementById("city");
    const email = document.getElementById("emails");
    const password = document.getElementById("passwords");
    const cpassword = document.getElementById("cpasswords");

    if (fullName.value.length === 0) {
      toast.error("Must enter name");
    } else if (phone.value.length === 0) {
      toast.error("Must enter phone");
    } else if (email.value.length === 0) {
      toast.error("Must enter email");
    } else if (password.value.length === 0) {
      toast.error("Must enter password");
    } else if (cpassword.value.length === 0) {
      toast.error("Must enter confirm password");
    } else if (password.value !== cpassword.value) {
      toast.error("Password and confirm password must be same");
    } else if (city.value.length === 0) {
      toast.error("Must enter city");
    } else {
      let imageUrl = "";

      if (agencyLogoss.length > 0) {
        try {
          let param = new FormData();
          param.append("avatars", agencyLogoss[0]);
          const profilephoto = await axios.post(`${api_url}blog/image`, param);
          imageUrl = profilephoto?.data?.data[0]?.url || "";
          setMyImage(imageUrl);
        } catch (error) {
          console.log(error);
        }
      }

      const params = {
        agencyId: agencyId, // Include agencyId
        city: city.value,
        fullName: fullName.value,
        phone: phone.value,
        email: email.value,
        password: password.value,
        agencyLogo: imageUrl || null,
      };

      try {
        const res = await axios.post(`${api_url}user/create`, params);
        if (res.data.status === "ok") {
          toast.success("Register successfully!");
          fetchStaffList(); // Refresh the staff list after creation
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchStaffList = async (agencyId) => {
    try {
      const res = await axios.get(`${api_url}user/staff?agencyId=${agencyId}`);
      if (res.data.status === "ok") {
        setStaffList(res.data.staff);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error("An error occurred while fetching the staff list:", error);
      toast.error("Failed to fetch staff list. Please try again later.");
    }
  };

  useEffect(() => {
    fetchStaffList();
  }, [agencyId]);

  return (
    <div>
      <ToastContainer />
      <h3 className="text-dark" style={{ marginTop: "2em" }}>
        Agency Staff List
      </h3>

      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                User details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="container-fluid">
                <form>
                  <div className="mb-2">
                    <label htmlFor="exampleInputName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                      id="fullName"
                      placeholder="Write Full Name"
                    />
                  </div>
                  <div className="pt-2">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                      id="emails"
                      placeholder="abc@xyz.com"
                    />
                  </div>
                  <div className="pt-2">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                      id="passwords"
                      placeholder="Create a strong password"
                    />
                  </div>
                  <div className="pt-2">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                      id="cpasswords"
                      placeholder="Confirm your password"
                    />
                  </div>
                  <div className="pt-2">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                      id="phone"
                      placeholder="+923-0000000"
                    />
                  </div>
                  <div className="pt-2">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label m-0"
                    >
                      City
                    </label>
                    <select
                      className="form-select form-select-md m-0 border-0 rounded-0 p-0 border-bottom"
                      aria-label=".form-select-md example"
                      name="city"
                      id="city"
                      required
                    >
                      <option value="" disabled selected>
                        Select The City
                      </option>
                      <option value="Islamabad">Islamabad</option>
                      <option value="Rawalpindi">Rawalpindi</option>
                    </select>
                  </div>
                  <div className="pt-2">
                    <label
                      htmlFor="exampleInputagencyname"
                      className="form-label"
                    >
                      Agent Profile
                    </label>
                    <input
                      type="file"
                      className="form-control bg-transparent border-0 rounded-0 p-0 border-bottom mytex"
                      id="agencyLogo"
                      name="agencyLogo"
                      onChange={(e) => setAgencyLogo(e.target.files)}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={submit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <a
        className="btn btn-primary"
        data-bs-toggle="modal"
        href="#exampleModalToggle"
        role="button"
      >
        Add Agency User
      </a>

      <table className="table" style={{ marginTop: "2em" }}>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Mobile</th>
            <th scope="col">Email</th>
          </tr>
        </thead>
        <tbody>
          {staffList.map((staff, index) => (
            <tr key={staff.id}>
              <th scope="row">{index + 1}</th>
              <td>{staff.fullName}</td>
              <td>{staff.phone}</td>
              <td>{staff.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Agencystaff;
