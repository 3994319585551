import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api_url from "../../ApiUrl";
import "./blogs.css"; // Ensure you import your CSS file

const LastetBlog = () => {
  const [myPost, setMyPost] = useState([]);

  useEffect(() => {
    axios
      .get(api_url + `blog/get`)
      .then((res) => {
        console.log("API response:", res.data);
        setMyPost(res.data.data);
      })
      .catch((err) => console.error("API error:", err));
  }, []);

  const getTextFromHTML = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const formatName = (name) => {
    return name
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "");
  };

  return (
    <div className="container-fluid">
      <div className="text-center mt-5">
        <h2
          style={{
            fontWeight: "600",
            fontSize: "28px",
            lineHeight: "28.8px",
          }}
        >
          Latest Blogs
        </h2>
        <img src={require("../../img/arrow.png")} alt="Arrow" />
      </div>
      <br />

      {myPost.length > 0 ? (
        <div className="row">
          {myPost.map((i) => {
            console.log("Rendering blog:", i);
            return (
              <div className="col-md-3" key={i?.id} style={{ padding: "10px" }}>
                <Link key={i.id} to={`/blog/${formatName(i.name)}/${i.id}`}>
                  <div className="card d-flex flex-column h-100">
                    <img
                      src={i?.image}
                      className="card-img-top"
                      alt={i?.name}
                    />
                    <div className="card-body flex-grow-1">
                      <h5
                        className="card-title"
                        style={{
                          fontWeight: "700",
                          fontSize: "18px",
                          lineHeight: "22.6px",
                        }}
                      >
                        {i?.name}
                      </h5>

                      <p
                        className="card-text text-muted"
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "22.42px",
                          marginTop: "auto",
                        }}
                      >
                        {getTextFromHTML(i?.description)
                          .split(/\s+/)
                          .slice(0, 10)
                          .join(" ")}
                        ....
                      </p>
                    </div>
                    <div className="card-footer">
                      <Link
                        key={i.id}
                        to={`/blog/${formatName(i.name)}/${i.id}`}
                        className="btn btn-outline-success p-2"
                        style={{
                          margin: "10px",
                          width: "100%",
                          textAlign: "center",
                          display: "inline-flex",
                          justifyContent: "center",
                        }}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No blogs available</p>
      )}
    </div>
  );
};

export default LastetBlog;
