import React from "react";
import video from "../../img/timelapes.mp4";

function Video() {
  return (
    <div className="background-video">
      <video autoPlay loop muted>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
}

export default Video;
